import React, { useEffect, useState, useCallback } from "react";
import { QRCodeSVG } from "qrcode.react";
import { domain, clientId, audience } from "./auth-config";

const AUTH_STORAGE_KEY = `@@auth0spajs@@::${clientId}::${audience}::openid profile email offline_access`;
const TOKEN_REFRESH_THRESHOLD_MS = 15 * 60 * 1000; // 15 minutes before expiration

function DeviceAuth({ onAuthSuccess }) {
  const [deviceCode, setDeviceCode] = useState("");
  const [userCode, setUserCode] = useState("");
  const [verificationUri, setVerificationUri] = useState("");
  const [error, setError] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const getStoredAuthData = useCallback(() => {
    const storedAuth = localStorage.getItem(AUTH_STORAGE_KEY);
    if (storedAuth) {
      try {
        return JSON.parse(storedAuth);
      } catch (e) {
        console.error("Error parsing stored auth data:", e);
        return null;
      }
    }
    return null;
  }, []);

  const refreshAccessToken = useCallback(async () => {
    if (refreshing) return;
    
    const authData = getStoredAuthData();
    if (!authData || !authData.body.refresh_token) {
      console.error("No refresh token available");
      return false;
    }

    try {
      setRefreshing(true);
      console.log("Attempting to refresh access token...");
      console.log("Using refresh token with length:", authData.body.refresh_token.length);
      
      const response = await fetch(`https://${domain}/oauth/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          grant_type: "refresh_token",
          client_id: clientId,
          refresh_token: authData.body.refresh_token,
          scope: "openid profile email offline_access",
          audience: audience
        }),
      });

      const data = await response.json();

      if (data.error) {
        console.error("Token refresh failed:", data.error_description);
        setRefreshing(false);
        return false;
      }

      // Preserve the refresh token if a new one wasn't provided
      const refreshToken = data.refresh_token || authData.body.refresh_token;
      
      // Calculate new expiration time
      const expiresAt = Math.floor(Date.now() / 1000) + data.expires_in;
      
      // Decode the new access token
      const accessTokenPayload = JSON.parse(atob(data.access_token.split(".")[1]));
      
      // Decode ID token if available
      let idTokenPayload = null;
      if (data.id_token && data.id_token.split(".").length === 3) {
        try {
          idTokenPayload = JSON.parse(atob(data.id_token.split(".")[1]));
          console.log("New ID Token received with claims:", 
            Object.keys(idTokenPayload).filter(key => 
              ["exp", "iat", "session_expires_at", "auth_time"].includes(key))
              .reduce((obj, key) => {
                obj[key] = idTokenPayload[key];
                return obj;
              }, {})
          );
        } catch (e) {
          console.error("Error parsing ID token:", e);
        }
      }
      
      // Update stored tokens
      const updatedAuthData = {
        body: {
          ...authData.body,
          access_token: data.access_token,
          refresh_token: refreshToken,
          id_token: data.id_token || authData.body.id_token,
          expires_in: data.expires_in,
          decodedToken: {
            user: accessTokenPayload,
            id: idTokenPayload || authData.body.decodedToken?.id
          },
        },
        expiresAt: expiresAt,
        // Store refresh token expiration if available from Auth0 response
        refreshExpiresAt: data.refresh_expires_in 
          ? Math.floor(Date.now() / 1000) + data.refresh_expires_in 
          : authData.refreshExpiresAt || null,
      };
      
      localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(updatedAuthData));
      
      console.log("Access token refreshed successfully");
      console.log("New token expiration:", new Date(expiresAt * 1000).toLocaleString());
      
      setRefreshing(false);
      return true;
    } catch (err) {
      console.error("Error refreshing token:", err);
      setRefreshing(false);
      return false;
    }
  }, [refreshing, getStoredAuthData]);

  const checkAndRefreshToken = useCallback(async () => {
    const authData = getStoredAuthData();
    
    if (!authData || !authData.body.access_token) {
      console.error("No access token found in storage");
      return;
    }
    
    try {
      // Parse the JWT to get expiration time
      const payload = JSON.parse(atob(authData.body.access_token.split(".")[1]));
      const expirationDate = new Date(payload.exp * 1000);
      const timeRemaining = expirationDate - new Date();
      
      console.log("Token status check:", {
        expirationDate: expirationDate.toLocaleString(),
        timeRemaining: Math.floor(timeRemaining / 1000) + " seconds",
      });
      
      // If token expires in less than the threshold, refresh it
      if (timeRemaining < TOKEN_REFRESH_THRESHOLD_MS && timeRemaining > 0) {
        console.log("Token expiring soon, refreshing...");
        await refreshAccessToken();
      } else if (timeRemaining <= 0) {
        console.log("Token has expired, refreshing...");
        await refreshAccessToken();
      }
    } catch (e) {
      console.error("Error checking token expiration:", e);
    }
  }, [getStoredAuthData, refreshAccessToken]);

  const checkTokenExpiration = useCallback(() => {
    const authData = getStoredAuthData();

    if (authData) {
      try {
        const accessToken = authData.body.access_token;
        const refreshToken = authData.body.refresh_token;
        const expiresAt = authData.expiresAt;

        if (accessToken) {
          try {
            const payload = JSON.parse(atob(accessToken.split(".")[1]));
            const expirationDate = new Date(payload.exp * 1000);
            const timeRemaining = expirationDate - new Date();
            const hoursRemaining = Math.floor(timeRemaining / (1000 * 60 * 60));

            console.log("Access Token Status:", {
              expirationDate: expirationDate.toLocaleString(),
              hoursRemaining: hoursRemaining,
              isExpired: new Date() > expirationDate,
              expiresAt: new Date(expiresAt * 1000).toLocaleString(),
            });
            
            // Check if the token contains session_expires_at claim
            if (payload.session_expires_at) {
              console.log("Session expiration from access token:", 
                new Date(payload.session_expires_at * 1000).toLocaleString());
            }
          } catch (e) {
            console.error("Error parsing access token:", e);
          }
        } else {
          console.error("No access token found in storage");
        }

        if (refreshToken) {
          // Try to decode the refresh token if possible (note: refresh tokens are often not JWTs)
          try {
            // First check if it's a JWT (has the correct format with two dots)
            if (refreshToken.split(".").length === 3) {
              const refreshPayload = JSON.parse(atob(refreshToken.split(".")[1]));
              console.log("Refresh Token Status:", {
                present: true,
                length: refreshToken.length,
                isJWT: true,
                expiresAt: refreshPayload.exp 
                  ? new Date(refreshPayload.exp * 1000).toLocaleString() 
                  : "No expiration in token",
                sessionExpiresAt: refreshPayload.session_expires_at
                  ? new Date(refreshPayload.session_expires_at * 1000).toLocaleString()
                  : "Not in token"
              });
            } else {
              // Not a JWT, check if we have session expiration from ID token or user info
              console.log("Refresh Token Status:", {
                present: true,
                length: refreshToken.length,
                isJWT: false,
                // Check ID token for session expiration
                sessionExpiresAt: authData.body.decodedToken?.user?.session_expires_at
                  ? new Date(authData.body.decodedToken.user.session_expires_at * 1000).toLocaleString()
                  : "Not available in tokens"
              });
              
              // Log the ID token claims if available
              if (authData.body.id_token && authData.body.id_token.split(".").length === 3) {
                const idTokenPayload = JSON.parse(atob(authData.body.id_token.split(".")[1]));
                console.log("ID Token claims:", {
                  exp: idTokenPayload.exp ? new Date(idTokenPayload.exp * 1000).toLocaleString() : "Not available",
                  session_expires_at: idTokenPayload.session_expires_at 
                    ? new Date(idTokenPayload.session_expires_at * 1000).toLocaleString() 
                    : "Not available"
                });
              }
            }
          } catch (e) {
            console.log("Refresh Token Status:", {
              present: true,
              length: refreshToken.length,
              isJWT: false,
              error: "Could not decode token",
              sessionExpiresAt: "Not available"
            });
          }
        } else {
          console.error("No refresh token found in storage");
        }
      } catch (e) {
        console.error("Error parsing stored auth data:", e);
      }
    } else {
      console.log("No auth data found in storage");
    }
  }, [getStoredAuthData]);

  const pollForToken = useCallback(
    async (deviceCode, interval) => {
      try {
        const response = await fetch(`https://${domain}/oauth/token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            grant_type: "urn:ietf:params:oauth:grant-type:device_code",
            device_code: deviceCode,
            client_id: clientId,
          }),
        });

        const data = await response.json();

        if (data.error === "authorization_pending") {
          const id = setTimeout(
            () => pollForToken(deviceCode, interval),
            interval * 1000
          );
          setTimeoutId(id);
          return;
        }

        if (data.error === "slow_down") {
          const id = setTimeout(
            () => pollForToken(deviceCode, interval * 2),
            interval * 2000
          );
          setTimeoutId(id);
          return;
        }

        if (data.error) {
          setError(data.error_description);
          if (
            data.error !== "expired_token" &&
            data.error !== "access_denied"
          ) {
            const id = setTimeout(
              () => pollForToken(deviceCode, interval),
              interval * 1000
            );
            setTimeoutId(id);
          }
          return;
        }

        if (data.access_token) {
          console.log("New tokens received, checking expiration:");
          
          // Decode the access token to get user info
          const accessTokenPayload = JSON.parse(atob(data.access_token.split(".")[1]));
          
          // Decode ID token if available
          let idTokenPayload = null;
          if (data.id_token && data.id_token.split(".").length === 3) {
            try {
              idTokenPayload = JSON.parse(atob(data.id_token.split(".")[1]));
              console.log("ID Token received with claims:", 
                Object.keys(idTokenPayload).filter(key => 
                  ["exp", "iat", "session_expires_at", "auth_time"].includes(key))
                  .reduce((obj, key) => {
                    obj[key] = idTokenPayload[key];
                    return obj;
                  }, {})
              );
            } catch (e) {
              console.error("Error parsing ID token:", e);
            }
          }
          
          // Verify we have a refresh token
          if (!data.refresh_token) {
            console.error("No refresh token received from Auth0");
            setError("Authentication failed: No refresh token received");
            return;
          }
          
          console.log("Refresh token received successfully");
          
          // Store auth data with additional information
          localStorage.setItem(
            AUTH_STORAGE_KEY,
            JSON.stringify({
              body: {
                access_token: data.access_token,
                refresh_token: data.refresh_token,
                id_token: data.id_token,
                token_type: "Bearer",
                expires_in: data.expires_in,
                scope: "openid profile email offline_access",
                decodedToken: {
                  user: accessTokenPayload,
                  id: idTokenPayload
                },
              },
              expiresAt: Math.floor(Date.now() / 1000) + data.expires_in,
              // Store refresh token expiration if available from Auth0 response
              refreshExpiresAt: data.refresh_expires_in 
                ? Math.floor(Date.now() / 1000) + data.refresh_expires_in 
                : null,
            })
          );

          checkTokenExpiration();
          if (onAuthSuccess) {
            onAuthSuccess();
          } else {
            window.location.reload();
          }
        }
      } catch (err) {
        console.error("Error during token polling:", err);
        setError("Failed to complete authentication");
        const id = setTimeout(
          () => pollForToken(deviceCode, interval),
          interval * 1000
        );
        setTimeoutId(id);
      }
    },
    [checkTokenExpiration, onAuthSuccess]
  );

  const startDeviceFlow = useCallback(async () => {
    try {
      const response = await fetch(`https://${domain}/oauth/device/code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          client_id: clientId,
          scope: "openid profile email offline_access",
          audience: audience,
        }),
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error_description);
        return;
      }

      console.log("Device flow initiated successfully");
      console.log("Requested scopes:", "openid profile email offline_access");
      console.log("Requested audience:", audience);
      
      setDeviceCode(data.device_code);
      setUserCode(data.user_code);
      setVerificationUri(data.verification_uri_complete);
      pollForToken(data.device_code, data.interval || 5);
    } catch (err) {
      console.error("Failed to start device flow:", err);
      setError("Failed to start device flow");
    }
  }, [pollForToken]);

  // Add periodic token check and refresh
  useEffect(() => {
    // Check if we already have valid tokens
    const authData = getStoredAuthData();
    if (authData && authData.body.access_token) {
      try {
        const payload = JSON.parse(atob(authData.body.access_token.split(".")[1]));
        const expirationDate = new Date(payload.exp * 1000);
        
        if (expirationDate > new Date()) {
          console.log("Found valid token on initialization, checking refresh token");
          checkTokenExpiration();
          
          // If we have a valid access token but no refresh token, we need to re-authenticate
          if (!authData.body.refresh_token) {
            console.error("No refresh token found, need to re-authenticate");
            localStorage.removeItem(AUTH_STORAGE_KEY);
            startDeviceFlow();
          }
        } else {
          console.log("Access token expired, attempting refresh");
          refreshAccessToken().then(success => {
            if (!success) {
              console.log("Refresh failed, starting new device flow");
              localStorage.removeItem(AUTH_STORAGE_KEY);
              startDeviceFlow();
            }
          });
        }
      } catch (e) {
        console.error("Error checking stored token:", e);
        startDeviceFlow();
      }
    } else if (!deviceCode) {
      startDeviceFlow();
    }
    
    // Initial check
    checkTokenExpiration();
    checkAndRefreshToken();
    
    // Check token status every hour
    const statusInterval = setInterval(checkTokenExpiration, 60 * 60 * 1000);
    
    // Check and refresh token every 15 minutes
    const refreshInterval = setInterval(checkAndRefreshToken, 15 * 60 * 1000);
    
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      clearInterval(statusInterval);
      clearInterval(refreshInterval);
    };
  }, [checkTokenExpiration, checkAndRefreshToken, deviceCode, startDeviceFlow, getStoredAuthData, refreshAccessToken, timeoutId]);

  return (
    <div
      className="device-auth"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
      }}
    >
      {deviceCode ? (
        <div style={{ textAlign: "center" }}>
          <h2>Device Authentication</h2>
          <div style={{ marginBottom: "20px" }}>
            <p>
              Your code:{" "}
              <strong
                style={{
                  fontSize: "24px",
                  letterSpacing: "2px",
                  padding: "10px",
                  background: "#f0f0f0",
                  borderRadius: "4px",
                }}
              >
                {userCode}
              </strong>
            </p>
          </div>

          <div style={{ marginBottom: "20px" }}>
            {verificationUri && (
              <QRCodeSVG
                value={verificationUri}
                size={256}
                level="H"
                includeMargin={true}
              />
            )}
          </div>

          <p>Scan the QR code or visit:</p>
          <p>
            <a href={verificationUri} target="_blank" rel="noopener noreferrer">
              {verificationUri}
            </a>
          </p>
          <p style={{ color: "#666" }}>Waiting for authentication...</p>
          <p style={{ fontSize: "14px", marginTop: "20px" }}>
            This will allow the application to display flight information on this device.
          </p>
        </div>
      ) : (
        <div>Initializing device authentication...</div>
      )}
      {error && (
        <p style={{ color: "red" }} className="error">
          {error}
        </p>
      )}
      {refreshing && (
        <p style={{ color: "blue" }}>Refreshing authentication token...</p>
      )}
    </div>
  );
}

export default DeviceAuth;
