/**
 * Utility functions for flight data processing
 */

/**
 * Converts a time string (like "08:30 AM") to a 24-hour format number for sorting
 * @param {string} timeString - Time in format "HH:MM AM/PM"
 * @returns {number} - Time in 24-hour format as a number (e.g., 830)
 */
export const parseTimeStringTo24HourFormat = (timeString) => {
  if (!timeString) return 0;
  
  const [time, period] = timeString.split(' ');
  let [hours, minutes] = time.split(':').map(Number);
  
  if (period === 'PM' && hours < 12) {
    hours += 12;
  } else if (period === 'AM' && hours === 12) {
    hours = 0;
  }
  
  return hours * 100 + minutes;
};

/**
 * Abbreviates a duration string for display
 * @param {string} duration - Duration in format "X hours Y minutes"
 * @returns {string} - Abbreviated duration (e.g., "5h 44m")
 */
export const abbreviateDuration = (duration) => {
  if (!duration) return '';
  
  const match = duration.match(/(\d+)\s*hour[s]?\s*(\d+)\s*minute[s]?/i);
  if (!match) return duration;
  
  const hours = parseInt(match[1]);
  const minutes = parseInt(match[2]);
  
  return `${hours}h ${minutes}m`;
};
