/**
 * Flight Constants
 *
 * This module contains constant values used throughout the flight status application.
 */

/** API address for real-time flight data */
export const API_ADDRESS = "https://flyby.colonmelvin.com/api/flight-data";

/** API address for development environment */
export const DEVELOPMENT_API_ADDRESS = "http://localhost:8000/api/flight-data";

/** WebSocket address for real-time flight data */
// export const WEBSOCKET_ADDRESS = "wss://flyby.colonmelvin.com:8000/ws";

/** Interval for WebSocket reconnection attempts (in milliseconds) */
export const RECONNECT_INTERVAL = 60000; // 1 minute

/** Mapping of airline codes to their respective logo image files */
export const airlineLogo = {
  AAL: require("../assets/american_airline_1.png"),
  ASA: require("../assets/alaska_airline.png"),
  DAL: require("../assets/delta_airline.png"),
  FFT: require("../assets/frontier.png"),
  EDV: require("../assets/delta_airline.png"),
  JBU: require("../assets/jetBlue.png"),
  UAL: require("../assets/united_airline.png"),
  SWA: require("../assets/southwest_airline.png"),
  NKS: require("../assets/spirit.png"),
  ICE: require("../assets/iceland.png"),
  DLH: require("../assets/lufthansa.png"),
  CMP: require("../assets/copa_airline.png"),
  VXP: require("../assets/avelo.jpg"),
  BHS: require("../assets/bahama_air.png"),
  BRE: require("../assets/breeze.png"),
  MYX: require("../assets/breeze.png"),
  SCX: require("../assets/sun_country.png"),
  RPA: require("../assets/republic_airways.png"),
  SKW: require("../assets/sky_west.png"),
  EJA: require("../assets/netjets.png"),
  ASH: require("../assets/mesa_airlines.png"),
  ENY: require("../assets/envoy_air.png"),
};

export const DEFAULT_JSON_DATA = "/path/to/your/default-data.json";
