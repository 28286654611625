import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import { Auth0Provider } from "@auth0/auth0-react";
import { domain, clientId, audience } from "./auth-config";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: audience,
      scope: "openid profile email offline_access",
    }}
    cacheLocation="localstorage"
    useRefreshTokens={true}
    useRefreshTokensFallback={true}
  >
    <App />
  </Auth0Provider>
  // </React.StrictMode>
);
